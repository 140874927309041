import React, { useEffect, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { toast } from 'react-hot-toast'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { Link, useNavigate } from 'react-router-dom'
import Api from '../../../Api'
import { Landing1, Logo2, LogoBSrE } from '../../../assets'
import Bantuan from './bantuan'

const Login = () => {
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [passwordType, setPasswordType] = useState('password')
    const [menu, setMenu] = useState('login')
    const token = localStorage.getItem("hris-token")

    const login = async (token) => {

        if (token) {
            try {
                const response = await Api.Login(email, password, token)
                if (response.data.results.data.user.role === 'SUPERADMIN') {
                    toast.error("Terjadi Kesalahan!")
                } else {
                    localStorage.setItem('hris-token', response.data.results.data.token)
                    localStorage.setItem('hris-role', response.data.results.data.user.role)
                    navigate('/dashboard')
                }

            } catch (error) {
                console.log(error.response.data.message)
                toast.error(error.response.data.message ?? "Error")
            }
        } else {
            // Tampilkan pesan atau lakukan tindakan sesuai jika CAPTCHA belum diverifikasi
            toast.error("Verifikasi gagal. Silakan coba lagi!")
        }
    }

    const ChangePasswordType = () => {
        if (passwordType === 'password') {
            setPasswordType('text')
        } else {
            setPasswordType('password')
        }
    }


    const { executeRecaptcha } = useGoogleReCaptcha();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!executeRecaptcha) {
            console.error('Execute reCAPTCHA not yet available');
            return;
        }

        setIsSubmitting(true);

        // Eksekusi reCAPTCHA untuk mendapatkan token
        const token = await executeRecaptcha('');
        login(token)
    };

    useEffect(() => {
        if (token) {
            navigate('/dashboard')
        }
    }, [])
    return (
        <div className='grid lg:grid-cols-2 grid-cols-1 lg:h-screen h-fit overflow-hidden'>
            <div className='bg-[#ECEEF6] lg:block hidden'>
                <div className='flex flex-col justify-between'>
                    <div className='px-[85px] mt-[51px] flex gap-2'>
                        <img src={Logo2} className='h-12' alt='Humanusia Logo' />
                        <img src={LogoBSrE} className='h-12' alt='Humanusia Logo' />
                    </div>
                    <div className=''>
                        <img src={Landing1} className='' alt='LandingImage' />
                    </div>
                </div>
            </div>
            <div className=''>
                <div className='flex flex-col gap-5 min-h-screen items-center justify-center'>
                    <div className='lg:hidden block'>
                        <div className='flex gap-2'>
                            <img src={Logo2} className='h-10' alt='Humanusia Logo' />
                            <img src={LogoBSrE} className='h-10' alt='Humanusia Logo' />
                        </div>
                    </div>
                    <div className='w-full max-w-md p-2'>
                        <h1 className='text-[30px] text-[#2F3044] font-medium capitalize'>Selamat Datang Kembali</h1>
                        <h2 className='text-[#A8A8A8] text-[12px] capitalize'>Masuk Kembali ke Akun Anda</h2>

                        <div className='border-b-2 flex flex-wrap items-center text-[16px] mt-5 mb-5'>
                            <button onClick={() => setMenu('login')} className={`${menu === 'login' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-5`}>Login</button>
                            <button onClick={() => setMenu('bantuan')} className={`${menu === 'bantuan' ? 'text-[#C1121F] border-b-[#C1121F] border-b-2 font-semibold' : 'text-[#D1D1D1]'}  py-[10px] px-5`}>Bantuan</button>
                        </div>

                        {menu === 'login' ?
                            <div className=''>
                                <h1 className='text-[13px] text-dark-3 font-medium mb-2'>Email</h1>
                                <input onChange={(e) => setEmail(e.target.value)} type='text' className="bg-white border border-[#780000] text-[#A8A8A8] text-[12px] rounded-lg block w-full py-[13px] px-[20px] mb-[15px]" placeholder='Masukkan Email...' />
                                <h1 className='text-[13px] text-dark-3 font-medium mb-2'>Kata Sandi</h1>
                                <div className='relative'>
                                    <input onChange={(e) => setPassword(e.target.value)} type={passwordType} className="bg-white border border-[#780000] text-[#A8A8A8] text-[12px] rounded-lg block w-full py-[13px] px-[20px]" placeholder='Masukkan Kata Sandi...' />
                                    <button onClick={ChangePasswordType} className='absolute right-5 top-3 text-xl text-[#A8A8A8]'>
                                        {passwordType === 'text' ?
                                            <AiOutlineEye />
                                            :
                                            <AiOutlineEyeInvisible />
                                        }
                                    </button>
                                </div>
                                <div className='flex justify-end gap-1 mt-3'>
                                    <Link to='/forgot-password'>
                                        <h1 className='text-[14px] text-dark-5 underline'>Lupa Kata Sandi?</h1>
                                    </Link>
                                </div>
                                <button onClick={handleSubmit} className='py-[10px] text-center text-white rounded-md bg-[#0E5073] text-[12px] w-full font-semibold mb-[14px] mt-[15px] capitalize'>Masuk</button>
                            </div>
                            : menu === 'bantuan' ?
                                <Bantuan />
                                : null
                        }
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Login