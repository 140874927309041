import React, { useEffect, useState } from 'react'
import { HiOutlinePencil, HiPaperClip } from 'react-icons/hi'
import { Modal, ModalDelete } from '../../../../../../components';
import { AiOutlineDelete, AiOutlineDownload, AiOutlinePlus } from 'react-icons/ai';
import Select from 'react-select'
import { selectStyle } from '../../../../../../utils/styles/select';
import imageHandle from '../../../../../../utils/imageHandle';
import Api from '../../../../../../Api';
import moment from 'moment';
import toast from 'react-hot-toast';
import { IoMdArrowDropdown } from 'react-icons/io';

const LKPKT = ({ laporanId, dataPKT, dataKinerja, dropdownTugasStruktural, dropdownTugasPokok, fresh }) => {

    const [idKinerja, setIdKinerja] = useState('')
    const [showAddFeedbackKinerja, setShowAddFeedbackKinerja] = useState(false)
    const [feedbackRhk, setFeedbackRhk] = useState('')

    const handlePenilaian = (id) => {
        setIdKinerja(id)
        setShowAddFeedbackKinerja(true)
    }

    const postPenilaianKinerja = async () => {
        try {
            const data = {
                "feedback": feedbackRhk,// Diatas Ekspektasi, Sesuai Ekspektasi, Dibawah Ekspektasi
            }
            await Api.PostPenilaianKinerjaBulanan(localStorage.getItem('hris-token'), data, idKinerja).then((response) => {
                fresh()
                toast.success('Berhasil Menambahkan Data')
                setShowAddFeedbackKinerja(!showAddFeedbackKinerja)
            }).catch((error) => {
                console.log(error)
                const e = error.response.data
                for (let i = 0; i < e.length; i++) {
                    toast.error(e[i])
                }
            })

        } catch (error) {
            console.log(error)
            toast.error('Gagal Menambahkan Data')
        }
    }

    const postAllPenilaianKinerja = async (feedback) => {
        try {
            const form = {
                "feedback": feedback,// Diatas Ekspektasi, Sesuai Ekspektasi, Dibawah Ekspektasi
                "laporanId": laporanId
            }
            dataKinerja && dataKinerja.map(async (val) => {
                val?.rencana_aksi && val?.rencana_aksi.map(async (v) => (
                    await Api.PostPenilaianRhkBulanan(localStorage.getItem('hris-token'), form, v.id).then((response) => {
                        fresh()
                        toast.success('Berhasil Mengubah Data')
                    }).catch((error) => {
                        console.log(error)
                        const e = error.response.data
                        for (let i = 0; i < e.length; i++) {
                            toast.error(e[i])
                        }
                    })
                ))
            })

        } catch (error) {
            console.log(error)
            toast.error('Gagal Menambahkan Data')
        }
    }

    return (
        <div>
            <div className='relative'>
                <Modal
                    activeModal={showAddFeedbackKinerja}
                    title={'Berikan Penilaian Hasil Kerja'}
                    buttonClose={() => setShowAddFeedbackKinerja(!showAddFeedbackKinerja)}
                    width={'695px'}
                    content={
                        <div className='space-y-6'>
                            <div className='space-y-[5px]'>
                                <h1 className="text-neutral-500 text-[11px] font-medium">Umpan Balik Atasan Langsung</h1>
                                <div className='w-full'>
                                    <div className="mb-[12px]">
                                        <div className='relative w-full'>
                                            <select onChange={(e) => setFeedbackRhk(e.target.value)} value={feedbackRhk} className='text-xs rounded-md border border-gray-300 outline-none text-gray-500 w-full py-[10px] px-[8px] appearance-none'>
                                                <option selected disabled value="">Pilih Umpan Balik</option>
                                                <option value="Diatas Ekspektasi">Diatas Ekspektasi</option>
                                                <option value="Sesuai Ekspektasi">Sesuai Ekspektasi</option>
                                                <option value="Dibawah Ekspektasi">Dibawah Eskpektasi</option>
                                            </select>
                                            <IoMdArrowDropdown className='absolute top-[9px] right-1 text-xl text-grey-medium' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='flex items-center justify-end gap-[12px] mt-5'>
                                <button onClick={() => setShowAddFeedbackKinerja(!showAddFeedbackKinerja)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Batal</button>
                                <button
                                    onClick={postPenilaianKinerja}
                                    className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Simpan</button>
                            </div>
                        </div>
                    }
                />
            </div>
            {/* <h1 className="text-zinc-800 text-xl font-semibold">Laporan Kinerja dan Prestasi Kerja Tertentu</h1> */}
            <div className='space-y-10'>
                <div>
                    <h1 className="text-zinc-800 text-sm font-medium">Kinerja</h1>
                    <div className='my-5 overflow-auto'>
                        <table className='min-w-full border-collapse'>
                            <thead>
                                <tr className='bg-[#EBF7FF] text-grey-thick text-sm font-medium whitespace-nowrap'>
                                    <th rowSpan={2} className='border py-3 px-5'>No.</th>
                                    <th rowSpan={2} className='border py-3 px-5'>Aksi</th>
                                    <th rowSpan={2} className='border py-3 px-5 min-w-[300px]'>Umpan Balik</th>
                                    <th rowSpan={2} className='border py-3 px-5 min-w-[200px]'>Tanggal/Bulan</th>
                                    <th rowSpan={2} className='border py-3 px-5 min-w-[200px]'>Uraian Kegiatan</th>
                                    <th rowSpan={2} className='border py-3 px-5 min-w-[200px]'>Waktu</th>
                                    <th rowSpan={2} className='border py-3 px-5 min-w-[200px]'>Hasil</th>
                                    <th rowSpan={2} className='border py-3 px-5 min-w-[200px]'>Bukti Dukung</th>
                                    <th colSpan={11} className='border py-3 px-5'>Sasaran Kinerja Asisten (SKA)</th>
                                </tr>
                                <tr className='bg-[#EBF7FF] text-grey-thick text-sm font-medium whitespace-nowrap'>
                                    {['Tugas Struktural Atasan yang Diintervensi', 'Jenis Tugas', 'Tugas Struktural Asisten', 'Target', 'Tugas Pokok', 'Target Kualitas', 'Target Kuantitas', 'Target Waktu', 'Target Biaya', 'Realisasi Kuantitas', 'Realisasi Biaya'].map((header, index) => (
                                        <th key={index} className='border py-3 px-5 min-w-[200px]'>{header}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataKinerja && dataKinerja.map((val, index) => (
                                        <tr key={index} className='border'>
                                            <td className='border py-3 px-5 text-grey-thick text-sm font-medium'>{index + 1}</td>
                                            <td className='border py-3 px-5 text-grey-thick text-sm font-medium'>
                                                <div className='flex items-center justify-center w-full gap-[12px]'>
                                                    <button
                                                        onClick={() => {
                                                            handlePenilaian(val?.id)
                                                        }}
                                                        className='w-[29px] h-[29px] bg-blue-thin rounded-[9px] flex items-center justify-center'>
                                                        <HiOutlinePencil className='text-blue-thick' />
                                                    </button>
                                                </div>
                                            </td>
                                            <td className='border py-3 px-5 text-grey-thick text-sm font-medium'>
                                                <ul className='space-y-2'>
                                                    {val?.penilaian && val?.penilaian.map((item, i) => (
                                                        <li>
                                                            <p className='text-xs'>{i + 1}. {item.penilai}</p>
                                                            <h1 className={`${item?.feedback === 'Sesuai Ekspektasi' ? 'text-white bg-[#51A7F5]' : item?.feedback === 'Dibawah Ekspektasi' ? 'text-white bg-[#EA5A54]' : item?.feedback === 'Diatas Ekspektasi' ? 'text-white bg-[#AED96C]' : ''} font-medium text-xs rounded-lg text-center p-2 w-fit`}>{item?.feedback === null ? "-" : item?.feedback}</h1>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </td>
                                            <td className='border py-3 px-5 text-grey-thick text-sm font-medium'>{val?.tanggal ? moment(val?.tanggal).format("D MMMM YYYY") : "-"}</td>
                                            <td className='border py-3 px-5 text-grey-thick text-sm font-medium'>{val?.uraian_kegiatan ?? ""}</td>
                                            <td className='border py-3 px-5 text-grey-thick text-sm font-medium'>{val?.waktu ?? ""}</td>
                                            <td className='border py-3 px-5 text-grey-thick text-sm font-medium'>{val?.hasil ?? ""}</td>
                                            <td className='border py-3 px-5 text-grey-thick text-sm font-medium'>
                                                <ul className='list-disc text-grey-thick text-xs font-medium space-y-1'>
                                                    {val?.files && val?.files.map((file, i) => (
                                                        <li className='flex gap-2 items-center '>
                                                            <div className='bg-blue-thin rounded-md flex gap-2 items-center p-2 text-xs whitespace-nowrap'><HiPaperClip /> <span>{file?.originalname}</span></div>
                                                            <a href={imageHandle(file?.path)} download={file?.file_name} target='_blank' className='w-[29px] h-[29px] bg-blue-thin rounded-[9px] flex items-center justify-center' rel="noreferrer">
                                                                <AiOutlineDownload className='text-sm text-blue-thick' />
                                                            </a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </td>
                                            <td className='border py-3 px-5 text-grey-thick text-sm font-medium min-w-[200px]'>{val?.rencanaHasilKerja?.rhk_atasan ?? ""}</td>
                                            <td className='border py-3 px-5 text-grey-thick text-sm font-medium min-w-[200px]'>{val?.rencanaHasilKerja?.type ?? ""}</td>
                                            <td className='border py-3 px-5 text-grey-thick text-sm font-medium min-w-[200px]'>{val?.rencanaHasilKerja?.name ?? ""}</td>
                                            <td className='border py-3 px-5 text-grey-thick text-sm font-medium min-w-[200px]'>{val?.rencanaHasilKerja?.target_rhk ?? ""}</td>
                                            <td className='border py-3 px-5 text-grey-thick text-sm font-medium min-w-[200px]'>{val?.rencana_aksi?.name ?? ""}</td>
                                            <td className='border py-3 px-5 text-grey-thick text-sm font-medium min-w-[200px]'>{val?.rencana_aksi?.target_kualitas ?? ""}</td>
                                            <td className='border py-3 px-5 text-grey-thick text-sm font-medium min-w-[200px]'>{val?.rencana_aksi?.target_kuantitas ?? ""}</td>
                                            <td className='border py-3 px-5 text-grey-thick text-sm font-medium min-w-[200px]'>{val?.rencana_aksi?.target_waktu ?? ""}</td>
                                            <td className='border py-3 px-5 text-grey-thick text-sm font-medium min-w-[200px]'>{val?.rencana_aksi?.target_biaya ?? ""}</td>
                                            <td className='border py-3 px-5 text-grey-thick text-sm font-medium min-w-[200px]'>{val?.realisasi_kuantitas ?? ""}</td>
                                            <td className='border py-3 px-5 text-grey-thick text-sm font-medium min-w-[200px]'>{val?.realisasi_biaya ?? ""}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div>
                    <h1 className="text-zinc-800 text-sm font-medium">Prestasi Kerja Tertentu</h1>
                    <div className='my-5 overflow-auto'>
                        <table className='min-w-full border-collapse'>
                            <thead>
                                <tr className='bg-[#EBF7FF] text-grey-thick text-sm font-medium whitespace-nowrap'>
                                    <th className='border py-3 px-5'>No.</th>
                                    <th className='border py-3 px-5'>Aksi</th>
                                    <th className='border py-3 px-5'>Tanggal/Bulan</th>
                                    <th className='border py-3 px-5'>Uraian Kegiatan</th>
                                    <th className='border py-3 px-5'>Dasar Pernugasan</th>
                                    <th className='border py-3 px-5'>Hasil</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataPKT && dataPKT.map((val, index) => (
                                    <tr key={index} className='border'>
                                        <td className='border py-3 px-5 text-grey-thick text-sm font-medium'>{index + 1}</td>
                                        <td className='border py-3 px-5 text-grey-thick text-sm font-medium'>{val?.tanggal ? moment(val?.tanggal).format("D MMMM YYYY") : "-"}</td>
                                        <td className='border py-3 px-5 text-grey-thick text-sm font-medium'>{val?.uraian_kegiatan}</td>
                                        <td className='border py-3 px-5 text-grey-thick text-sm font-medium'>{val?.dasar_penugasan}</td>
                                        <td className='border py-3 px-5 text-grey-thick text-sm font-medium'>{val?.hasil}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LKPKT