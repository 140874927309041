import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { AiOutlineSearch } from 'react-icons/ai'
import { HiOutlinePencil } from 'react-icons/hi'
import Api from '../../../../Api'
import { Modal, Navbar, Pagination } from '../../../../components'
import languages from '../../../../utils/languages'
import Select from 'react-select'
import { selectStyle } from '../../../../utils/styles/select'

const HakAkses = () => {
    const [refresh, setRefresh] = useState(false)
    const [showModalEdit, setShowModalEdit] = useState(false)
    const [dataUser, setDataUser] = useState('')
    const limit = 10

    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState('')
    const [name, setName] = useState('')


    const [dataUnitKerja, setDataUnitKerja] = useState([])
    const [unitKerja, setUnitKerja] = useState([])
    const [selected, setSelected] = useState([])

    const getUnitKerja = async () => {
        try {
            await Api.GetUnitKerjaEselon(localStorage.getItem('hris-token')).then((response) => {
                const data = response?.data?.results?.data
                const options = data && data.map(val => ({
                    "value": val.name,
                    "label": val.name
                }))
                setDataUnitKerja(options)
            }).catch((error) => {
                console.log(error)
            })
        } catch (error) {
            console.log(error)
        }
    }

    const handleCahnge = (selectedOption) => {
        const data = []
        selectedOption && selectedOption.map(val => (
            data.push(val.value)
        ))
        setSelected(selectedOption)
        setUnitKerja(data)
    }

    const [hakAkses, setHakAkses] = useState({
        absensi: {
            all: false,
            penempatan: false,
            bawahan: false,
            unitKerjaEselon: false
        }
    });

    const handleCheckboxChange = (checkboxName) => {
        if (checkboxName === "all") {
            const newValue = !hakAkses.absensi.all;
            setHakAkses({
                absensi: {
                    all: newValue,
                    penempatan: newValue,
                    bawahan: newValue,
                    unitKerjaEselon: newValue
                }
            });
        } else {
            const updatedCheckboxes = {
                ...hakAkses.absensi,
                [checkboxName]: !hakAkses.absensi[checkboxName]
            };

            // Check if both penempatan and bawahan are true
            const allChecked = updatedCheckboxes.penempatan && updatedCheckboxes.bawahan && updatedCheckboxes.unitKerjaEselon;

            setHakAkses({
                absensi: {
                    ...updatedCheckboxes,
                    all: allChecked
                }
            });
        }
    };
    const handleCheckboxChangePerdin = (checkboxName) => {
        if (checkboxName === "all") {
            const newValue = !hakAkses.verifikasi_perdin.all;
            setHakAkses({
                ...hakAkses,
                verifikasi_perdin: {
                    all: newValue,
                    penempatan: newValue,
                    bawahan: newValue,
                }
            });
        } else {
            const updatedCheckboxes = {
                ...hakAkses.verifikasi_perdin,
                [checkboxName]: !hakAkses.verifikasi_perdin[checkboxName]
            };

            // Check if both penempatan and bawahan are true
            const allChecked = updatedCheckboxes.penempatan && updatedCheckboxes.bawahan;

            setHakAkses({
                ...hakAkses,
                verifikasi_perdin: {
                    ...updatedCheckboxes,
                    all: allChecked
                }
            });
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true)
    };

    const handlePrevChange = () => {
        if (currentPage === 1) {
            setCurrentPage(1)
        } else {
            setCurrentPage(currentPage - 1);
        }
        setRefresh(true)
    };

    const handleNextChange = () => {
        if (currentPage === totalPages) {
            setCurrentPage(totalPages)
        } else {
            setCurrentPage(currentPage + 1);
        }
        setRefresh(true)
    };
    const getData = async () => {
        try {
            const response = await Api.GetHakAkses(localStorage.getItem('hris-token'), currentPage, limit, name)
            // console.log(response, 'userMgmnt')
            setDataUser(response.data.results.data)
            setTotalPages(response.data.results.totalPages)
        } catch (error) {

        }
    }
    const [detail, setDetail] = useState([])
    const getDataById = async (id) => {
        setShowModalEdit(true)
        try {
            const response = await Api.GetHakAksesById(localStorage.getItem('hris-token'), id)
            // console.log(response, 'userMgmnt')
            const res = response.data.results.data
            setDetail(res)
            setHakAkses({
                absensi: {
                    all: res?.absensi?.all,
                    penempatan: res?.absensi?.penempatan,
                    bawahan: res?.absensi?.bawahan,
                    unitKerjaEselon: res?.absensi?.unitKerjaEselon || res?.absensi?.unitKerjaEselon.length > 0 ? false : true
                },
                verifikasi_perdin: {
                    all: res?.verifikasi_perdin?.all,
                    penempatan: res?.verifikasi_perdin?.penempatan,
                    bawahan: res?.verifikasi_perdin?.bawahan
                }
            })
            setUnitKerja(res?.absensi?.unitKerjaEselon ?? [])
            const data = res?.absensi?.unitKerjaEselon && res?.absensi?.unitKerjaEselon.map(val => ({
                "value": val,
                "label": val
            }))
            setSelected(data ?? [])
            console.log("data", data)
        } catch (error) {

        }
    }
    const updateData = async (id) => {
        try {
            let data = hakAkses
            if (hakAkses.absensi.unitKerjaEselon) {
                data.absensi.unitKerjaEselon = unitKerja
            } else {
                data.absensi.unitKerjaEselon = []
            }
            await Api.UpdateHakAkses(localStorage.getItem('hris-token'), id, data).then((response) => {
                setRefresh(true)
                setShowModalEdit(false)
                resetData()
                toast.success("Data Berhasil Diubah")
            }).catch((error) => {
                console.log(error)
                const e = error.response.data.errors.error.details
                for (let i = 0; i < e.length; i++) {
                    toast.error(e[i].message)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }
    const handleCloseModal = () => {
        setShowModalEdit(!showModalEdit)
        resetData()
    }
    const resetData = () => {
        setUnitKerja([])
        setSelected([])
        setHakAkses({
            absensi: {
                all: false,
                penempatan: false,
                bawahan: false,
                unitKerjaEselon: false
            }
        })
    }
    console.log(unitKerja)
    console.log(selected)
    console.log(hakAkses)
    useEffect(() => {
        getData()
        setRefresh(false)
    }, [refresh, name])
    useEffect(() => {
        getUnitKerja()
    }, [])
    return (
        <>
            <div>
                <Modal
                    activeModal={showModalEdit}
                    title={'Edit Hak Akses'}
                    buttonClose={() => handleCloseModal()}
                    width={'550px'}
                    content={
                        <div className='space-y-[25px]'>
                            <div className='w-full'>
                                <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Nama Pegawai <span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <p className='bg-base-outline w-full border pr-2 rounded-md py-[10px] pl-[21.74px] text-grey-thick text-[12px]' >{detail?.user?.fullname}</p>
                            </div>
                            <div className='w-full'>
                                <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Jabatan <span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <p className='bg-base-outline w-full border pr-2 rounded-md py-[10px] pl-[21.74px] text-grey-thick text-[12px]' >{detail?.user?.user_detail?.job_position?.position_name}</p>
                            </div>
                            <div className=''>
                                <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Akses Rekap Kehadiran & Absensi <span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <label className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        className="form-checkbox h-5 w-5 text-blue-600"
                                        value={"semua"}
                                        checked={hakAkses?.absensi?.all}
                                        onChange={() => handleCheckboxChange("all")}
                                    />
                                    <span className="text-gray-700">Semua</span>
                                </label>
                                <label className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        className="form-checkbox h-5 w-5 text-blue-600"
                                        value={"penempatan"}
                                        checked={hakAkses?.absensi?.penempatan}
                                        onChange={() => handleCheckboxChange("penempatan")}
                                        disabled={hakAkses?.absensi?.all}
                                    />
                                    <span className="text-gray-700">Berdasarkan Penempatan</span>
                                </label>
                                <label className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        className="form-checkbox h-5 w-5 text-blue-600"
                                        value={"bawahan"}
                                        checked={hakAkses?.absensi?.bawahan}
                                        onChange={() => handleCheckboxChange("bawahan")}
                                        disabled={hakAkses?.absensi?.all}
                                    />
                                    <span className="text-gray-700">Bawahan Langsung</span>
                                </label>
                                <label className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        className="form-checkbox h-5 w-5 text-blue-600"
                                        value={"unitKerjaEselon"}
                                        checked={hakAkses?.absensi?.unitKerjaEselon}
                                        onChange={() => handleCheckboxChange("unitKerjaEselon")}
                                        disabled={hakAkses?.absensi?.all}
                                    />
                                    <span className="text-gray-700">Unit Kerja</span>
                                </label>
                                {hakAkses?.absensi?.unitKerjaEselon ?
                                    <Select
                                        isMulti
                                        value={selected}
                                        styles={selectStyle}
                                        onChange={data => handleCahnge(data)}
                                        options={dataUnitKerja}
                                    />
                                    : null}
                            </div>
                            <div className=''>
                                <h1 className='text-dark-5 text-[13px] font-[500] mb-[7px]'>Akses Verifikasi Perjalanan Dinas <span className='text-[#780000] ml-[4px]'>*</span></h1>
                                <label className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        className="form-checkbox h-5 w-5 text-blue-600"
                                        value={"semua"}
                                        checked={hakAkses?.verifikasi_perdin?.all}
                                        onChange={() => handleCheckboxChangePerdin("all")}
                                    />
                                    <span className="text-gray-700">Semua</span>
                                </label>
                                <label className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        className="form-checkbox h-5 w-5 text-blue-600"
                                        value={"penempatan"}
                                        checked={hakAkses?.verifikasi_perdin?.penempatan}
                                        onChange={() => handleCheckboxChangePerdin("penempatan")}
                                        disabled={hakAkses?.verifikasi_perdin?.all}
                                    />
                                    <span className="text-gray-700">Berdasarkan Penempatan</span>
                                </label>
                                <label className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        className="form-checkbox h-5 w-5 text-blue-600"
                                        value={"bawahan"}
                                        checked={hakAkses?.verifikasi_perdin?.bawahan}
                                        onChange={() => handleCheckboxChangePerdin("bawahan")}
                                        disabled={hakAkses?.verifikasi_perdin?.all}
                                    />
                                    <span className="text-gray-700">Bawahan Langsung</span>
                                </label>
                                {/* <label className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        className="form-checkbox h-5 w-5 text-blue-600"
                                        value={"unitKerjaEselon"}
                                        checked={hakAkses?.verifikasi_perdin?.unitKerjaEselon}
                                        onChange={() => handleCheckboxChangePerdin("unitKerjaEselon")}
                                        disabled={hakAkses?.verifikasi_perdin?.all}
                                    />
                                    <span className="text-gray-700">Unit Kerja</span>
                                </label>
                                {hakAkses?.verifikasi_perdin?.unitKerjaEselon ?
                                    <Select
                                        isMulti
                                        value={selected}
                                        styles={selectStyle}
                                        onChange={data => handleCahnge(data)}
                                        options={dataUnitKerja}
                                    />
                                    : null} */}
                            </div>
                            <div className='flex items-center justify-end gap-[12px] mt-5'>
                                <button onClick={() => handleCloseModal()} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>{languages.language === 'id' ? "Batal" : "Cancel"}</button>
                                <button onClick={() => updateData(detail.id)} className='bg-[#0E5073] text-white text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>{languages.language === 'id' ? "Simpan" : "Submit"}</button>
                            </div>
                        </div>
                    }
                />
            </div>
            <div className='w-full space-y-[24px] overflow-hidde'>
                <Navbar SubNavbar={true} NameSubNavbar="Hak Akses" LinkBack={'/dashboard'} />
                <div className='bg-white border shadow-sm rounded-[12px] px-[22px] py-[36px]'>
                    <div className='flex flex-col lg:flex-row items-center justify-between'>
                        <div className='w-full lg:w-auto'>
                            <h1 className='text-dark-3 text-[16px] font-[500]'>Hak Akses</h1>
                            <h1 className='text-[#A8A8A8] text-xs font-[500]'>Daftar Hak Akses</h1>
                        </div>
                        <div className='flex flex-col lg:flex-row w-full lg:w-auto mt-3 lg:mt-0 items-start lg:items-center gap-[10px]'>
                            <div className='relative'>
                                <AiOutlineSearch className='absolute left-[14px] top-[10px] text-[#A8A8A8] text-lg' />
                                <input onChange={(e) => setName(e.target.value)} placeholder='Cari Nama Pegawai...' className='text-[#A8A8A8] text-sm font-[500] pl-12 border rounded-[12px] py-2 w-full lg:w-[300px]' />
                            </div>
                            {/* <button onClick={ () => setShowModalAdd(!showModalAdd)} className='bg-[#003049] py-2 px-4 rounded-[10px] flex items-center gap-[16px]'>
                                <AiOutlinePlus className='text-xl text-white'/>
                                <h1 className='text-dark-9 text-sm font-[500]'>{languages.language === 'id' ?"Tambah Pengguna":"Add User"}</h1>
                            </button> */}
                        </div>
                    </div>
                    <div className='mt-[44px] overflow-auto'>
                        <table className='w-full space-y-[10px]'>
                            <div className='flex items-center gap-3 bg-[#EBF7FF] px-[14px] py-[8px] rounded-[3px]'>
                                <div className='flex items-center gap-[15px] min-w-[50px] max-w-[50px]'>
                                    <h1 className='text-dark-5 text-xs font-[600]'>No</h1>
                                </div>
                                <div className='flex items-center gap-[15px] min-w-[250px] max-w-[250px]'>
                                    <h1 className='text-dark-5 text-xs font-[600]'>{languages.language === 'id' ? "Nama Pegawai" : "Employee Name"}</h1>
                                </div>
                                <div className='flex items-center gap-[15px] min-w-[250px] max-w-[250px]'>
                                    <h1 className='text-dark-5 text-xs font-[600]'>Email</h1>
                                </div>
                                {/* <div className='flex items-center gap-[15px] min-w-[100px] max-w-[100px]'>
                                    <h1 className='text-dark-5 text-xs font-[600]'>{languages.language === 'id' ? "Role" : "User Role"}</h1>
                                </div> */}
                                <div className='gap-[15px] min-w-[200px] max-w-[200px] text-center'>
                                    <h1 className='text-dark-5 text-xs font-[600]'>Akses Rekap<br />Kehadiran & Absensi</h1>
                                </div>
                                <div className='gap-[15px] min-w-[200px] max-w-[200px] text-center'>
                                    <h1 className='text-dark-5 text-xs font-[600]'>Akses Verifikasi Perjalanan Dinas</h1>
                                </div>
                                <div className='flex items-center justify-center gap-[15px] w-full'>
                                    <h1 className='text-dark-5 text-xs font-[600]'>{languages.language === 'id' ? "Aksi" : "Action"}</h1>
                                </div>
                            </div>
                            {dataUser.length === 0 ?
                                <div className='flex items-center gap-3 bg-[#F8F9FB] px-[14px] py-[8px] rounded-[3px]'>
                                    <div className='min-w-[50px] max-w-[50px]'>
                                        <h1 className='text-dark-5 text-xs font-[600] line-clamp-1'>-</h1>
                                    </div>
                                    <div className='min-w-[250px] max-w-[250px]'>
                                        <h1 className='text-dark-5 text-xs font-[600] line-clamp-1'>-</h1>
                                    </div>
                                    <div className='min-w-[100px] max-w-[100px]'>
                                        <h1 className='text-dark-5 text-xs font-[600] line-clamp-1'>-</h1>
                                    </div>
                                    {/* <div className='min-w-[300px] max-w-[300px]'>
                                        <h1 className='text-dark-5 text-xs font-[600] line-clamp-1'>-</h1>
                                    </div> */}
                                    <div className='min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-dark-5 text-xs font-[600] line-clamp-1'>-</h1>
                                    </div>
                                    <div className='min-w-[200px] max-w-[200px]'>
                                        <h1 className='text-dark-5 text-xs font-[600] line-clamp-1'>-</h1>
                                    </div>
                                    <div className='w-full flex items-center justify-center gap-[5px]'>
                                        <h1 className='text-dark-5 text-xs font-[600] line-clamp-1'>-</h1>
                                    </div>
                                </div>
                                :
                                Object.values(dataUser).map((data, index) => {
                                    return (
                                        <div key={index} className='flex items-center gap-3 bg-[#F8F9FB] px-[14px] py-[8px] rounded-[3px]'>
                                            <div className='min-w-[50px] max-w-[50px]'>
                                                <h1 className='text-dark-5 text-xs font-[600] line-clamp-1'>{index + 1}</h1>
                                            </div>
                                            <div className='min-w-[250px] max-w-[250px]'>
                                                <h1 className='text-dark-5 text-xs font-[600] line-clamp-1'>{data?.user?.fullname}</h1>
                                            </div>
                                            <div className='min-w-[250px] max-w-[250px]'>
                                                <h1 className='text-dark-5 text-xs font-[600] line-clamp-1'>{data?.user?.email}</h1>
                                            </div>
                                            {/* <div className='min-w-[100px] max-w-[100px]'>
                                                <h1 className='text-dark-5 text-xs font-[600] line-clamp-1'>{data?.user?.role}</h1>
                                            </div> */}
                                            <div className='min-w-[200px] max-w-[200px] text-center'>
                                                <h1 className='text-dark-5 text-xs font-[600]'>
                                                    {data?.absensi?.all
                                                        ? "Semua"
                                                        : data?.absensi?.penempatan && data?.absensi?.bawahan
                                                            ? "Penempatan & Bawahan Langsung"
                                                            : data?.absensi?.penempatan && data?.absensi?.unitKerjaEselon?.length > 0
                                                                ? `Penempatan & Unit Kerja `
                                                                : data?.absensi?.bawahan && data?.absensi?.unitKerjaEselon?.length > 0
                                                                    ? `Bawahan Langsung & Unit Kerja `
                                                                    : data?.absensi?.bawahan
                                                                        ? "Bawahan Langsung"
                                                                        : data?.absensi?.penempatan
                                                                            ? "Penempatan"
                                                                            : "-"
                                                    }
                                                </h1>
                                                {/* <div className='grid grid-cols-2 items-center '>
                                                <h1 className='text-dark-5 text-xs font-[600]'>Kehadiran</h1>
                                                <h1 className='text-dark-5 text-xs font-[600]'>Laporan</h1>
                                            </div> */}
                                            </div>
                                            <div className='min-w-[200px] max-w-[200px] text-center'>
                                                <h1 className='text-dark-5 text-xs font-[600]'>
                                                    {data?.verifikasi_perdin?.all
                                                        ? "Semua"
                                                        : data?.verifikasi_perdin?.penempatan && data?.verifikasi_perdin?.bawahan
                                                            ? "Penempatan & Bawahan Langsung"
                                                            : data?.verifikasi_perdin?.penempatan && data?.verifikasi_perdin?.unitKerjaEselon?.length > 0
                                                                ? `Penempatan & Unit Kerja `
                                                                : data?.verifikasi_perdin?.bawahan && data?.verifikasi_perdin?.unitKerjaEselon?.length > 0
                                                                    ? `Bawahan Langsung & Unit Kerja `
                                                                    : data?.verifikasi_perdin?.bawahan
                                                                        ? "Bawahan Langsung"
                                                                        : data?.verifikasi_perdin?.penempatan
                                                                            ? "Penempatan"
                                                                            : "-"
                                                    }
                                                </h1>
                                                {/* <div className='grid grid-cols-2 items-center '>
                                                <h1 className='text-dark-5 text-xs font-[600]'>Kehadiran</h1>
                                                <h1 className='text-dark-5 text-xs font-[600]'>Laporan</h1>
                                            </div> */}
                                            </div>
                                            <div className='w-full flex items-center justify-center gap-[5px]'>
                                                <button
                                                    onClick={() => getDataById(data.id)}
                                                    className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                    <HiOutlinePencil className='text-[#003049]' />
                                                </button>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </table>
                    </div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                        onPrevChange={handlePrevChange}
                        onNextChange={handleNextChange}
                    />
                </div>
            </div>
        </>
    )
}

export default HakAkses