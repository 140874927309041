import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { AiOutlineDownload } from 'react-icons/ai'
import { BiDownload, BiPrinter } from 'react-icons/bi'
import { HiPaperClip } from 'react-icons/hi'
import { IoEyeOutline } from 'react-icons/io5'
import { useLocation } from 'react-router-dom'
import Api from '../../../../../../../Api'
import { Modal, Navbar } from '../../../../../../../components'
import imageHandle from '../../../../../../../utils/imageHandle'
import languages from '../../../../../../../utils/languages'
import { IconLocFingerprint } from '../../../../../../../assets'
import toast from 'react-hot-toast'
import ExportLaporanPeriodik from '../../../../../../../export/ExportLaporanPeriodik'

const DetailLaporanSayaPeriode = () => {
    const params = useLocation()
    const idLaporan = params?.state?.id

    const [refresh, setRefresh] = useState(false)

    const [data, setData] = useState([])
    const [dataPerilakuKerja, setDataPerilakuKerja] = useState([])

    const [dataRHK, setDataRHK] = useState([])
    const [dataTambahan, setDataTambahan] = useState([])
    const [dataPendidikan, setDataPendidikan] = useState([])
    const [dataFileSKInovasi, setDataFileSKInovasi] = useState([])
    const [dataFileSKInovasiBulanan, setDataFileSKInovasiBulanan] = useState([])
    const [dataPegawaiDinilai, setDataPegawaiDinilai] = useState([])
    const [signatureFile, setSignatureFile] = useState()

    const getLKBawahanById = async () => {
        try {
            await Api.GetLaporanTahunanById(localStorage.getItem('hris-token'), idLaporan).then((response) => {
                const res = response.data.results
                setData(res.laporanTahunan)
                setDataRHK(res.rencanaHasilKerja)
                setDataTambahan(res.tugasTambahan)
                setDataPendidikan(res?.pendidikan)
                setDataFileSKInovasi(res.files)
                setDataFileSKInovasiBulanan(res.filesBulanan)
                setDataPerilakuKerja(res.dataPerilakuKerja)
                setDataPegawaiDinilai(res.pegawaiDinilai)
                setSignatureFile(res.signatureFile)
            })
        } catch (error) {
            console.log(error)
        }
    }

    const [showDetailBuktiDukung, setShowDetailBuktiDukung] = useState(false)
    const [detailBuktiDukungBulanan, setDetailBuktiDukungBulanan] = useState([])

    const handleDetailBuktiDukung = (bulanan, periode) => {
        setShowDetailBuktiDukung(true)
        setDetailBuktiDukungBulanan(bulanan)
    }

    const [dokumenExport, setDokumenExport] = useState()
    const handleFile = (file) => {
        setDokumenExport(file)
    }

    const [verifikasi, setVerifikasi] = useState(false)
    const [passphrase, setPassphrase] = useState("")
    const submitPassprashe = async () => {
        try {
            console.log(signatureFile?.pegawaiDinilai?.id === userId)
            const formData = new FormData();
            if (dataPegawaiDinilai.id === userId) {
                formData.append('file', dokumenExport, 'document.pdf');
            }
            formData.append('passphrase', passphrase);
            await Api.PostPassphraseLaporanPeriodik(localStorage.getItem('hris-token'), idLaporan, formData).then((response) => {
                toast.success('Berhasil menandatangani dokumen!')
                setVerifikasi(false)
                // navigate('/laporan-kinerja/detail-saya', { state: { id: skpId } })
            }).catch((error) => {
                console.log(error.response.data.message)
                setVerifikasi(false)
                toast.error(error.response.data.message ?? 'Gagal menandatangani dokumen!')
            })
        } catch (error) {
            console.log(error)
            toast.error('Error!')
        }
    }

    const [userId, setUserId] = useState('')
    const fetchUser = async () => {
        try {
            const response = await Api.Fetch(localStorage.getItem('hris-token'))
            setUserId(response.data.results.user.id)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchUser()
        getLKBawahanById()
        setRefresh(false)
    }, [refresh])
    return (
        <div className='bg-[#F8F9FB] min-h-screen'>
            <Modal
                activeModal={showDetailBuktiDukung}
                title={"Detail Bukti Dukung"}
                buttonClose={() => setShowDetailBuktiDukung(!showDetailBuktiDukung)}
                width={'700px'}
                content={
                    <div className='overflow-auto min-h-[300px] max-h-[400px] space-y-5'>
                        {detailBuktiDukungBulanan && detailBuktiDukungBulanan.map((bulan) => (
                            <div>
                                <div className='flex gap-3 mb-2'>
                                    <p className='text-grey-thick text-xs font-medium'>Bukti Dukung Bulan</p>
                                    <p className='text-grey-thick text-xs font-medium'>: {bulan?.bulan}</p>
                                </div>
                                <table className='min-w-full'>
                                    <thead>
                                        <tr className='bg-[#EBF7FF] text-grey-thick text-xs font-medium whitespace-nowrap'>
                                            <th className='border py-3 px-5'>No.</th>
                                            <th className='border py-3 px-5'>Tanggal</th>
                                            <th className='border py-3 px-5'>Nama Bukti Dukung</th>
                                            <th className='border py-3 px-5'>Realisasi Kuantitas</th>
                                            <th className='border py-3 px-5'>Realisasi Biaya</th>
                                            <th className='border py-3 px-5'>Dokumen</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {bulan?.kinerja && bulan?.kinerja.map((val, idx) => (
                                            <tr key={idx} className='border'>
                                                <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>{idx + 1}.</td>
                                                <td className='border py-3 px-5 text-grey-thick text-xs font-medium whitespace-nowrap'>{val?.tanggal}</td>
                                                <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>{val?.hasil}</td>
                                                <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>{val?.realisasi_kuantitas}</td>
                                                <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>{val?.realisasi_biaya}</td>
                                                <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>
                                                    <ul className='list-disc text-grey-thick text-xs font-medium'>
                                                        {val?.files && val?.files.map((file, i) => (
                                                            <li key={i} className='flex gap-2 items-center '>
                                                                <div className='bg-blue-thin rounded-md flex gap-2 items-center p-2 text-xs whitespace-nowrap'><HiPaperClip /> <span>{file?.file_name}</span></div>
                                                                <a href={imageHandle(file?.file_path)} download={file?.file_name} target='_blank' className='w-[29px] h-[29px] bg-blue-thin rounded-[9px] flex items-center justify-center'>
                                                                    <AiOutlineDownload className='text-sm text-blue-thick' />
                                                                </a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ))}
                    </div>
                }
            />
            <Modal
                activeModal={verifikasi}
                title={''}
                buttonClose={() => setVerifikasi(false)}
                width={'550px'}
                content={
                    <div className='space-y-6'>
                        <div className="text-center text-sky-950 text-base font-semibold">Apakah anda ingin menandatangani surat tersebut ?</div>
                        <div>
                            <input type='text' onChange={(e) => setPassphrase(e.target.value)} value={passphrase} className='text-zinc-700 text-xs font-normal py-[10px] px-[8px] rounded-md border outline-none w-full' placeholder='passpharse' />
                        </div>
                        <div className='flex items-center justify-center gap-[12px] mt-5'>
                            <button onClick={() => setVerifikasi(false)} className='bg-[#ECECEC] text-[#003049] text-sm rounded-[6px] w-[100px] py-[10px] px-[25px]'>Batal</button>
                            {/* <button onClick={submitPassprashe} className='bg-[#0E5073] text-white text-sm rounded-[6px] py-[10px] px-[25px]'>Tanda tangan</button> */}
                            <ExportLaporanPeriodik data={data} fileExport={handleFile} onClick={submitPassprashe} />
                        </div>
                    </div>
                }
            />
            <div className='w-full space-y-[24px] overflow-hidden'>
                <Navbar SubNavbar={true} NameSubNavbar={languages.language === 'id' ? "Detail Penilaian" : "Detail Evaluation"} LinkBack={'/laporan-kinerja'} />
                <div className='w-full flex justify-between lg:w-auto'>
                    <h1 className='text-dark-3 text-[20px] font-[600]'>Detail Laporan Kinerja Saya</h1>
                    <div className='flex gap-2 items-center justify-end'>
                        <div className={`flex gap-2 items-center py-2 px-5 border border-gray-400 rounded-lg ${data?.status === "approved" ? 'border-[#00AB9A] bg-[#E6FFFC] text-[#00AB9A]' : data?.status === "perbaiki" || data?.status === "pending" ? 'border-[#D7B700] bg-[#FFFADD] text-[#D7B700]' : ''}`}>
                            <p className='capitalize text-sm'>{data?.status === "approved" ? "Disetujui" : data?.status === "pending" ? "Belum Dinilai" : data?.status}</p>
                            {data?.status === "approved" ?
                                <svg width="20" height="20" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.375 0C5.54063 0 0 5.54063 0 12.375C0 19.2094 5.54063 24.75 12.375 24.75C19.2094 24.75 24.75 19.2094 24.75 12.375C24.75 5.54063 19.2094 0 12.375 0ZM17.739 10.2825C17.8378 10.1696 17.913 10.0381 17.9602 9.89569C18.0074 9.7533 18.0256 9.6029 18.0138 9.45336C18.002 9.30381 17.9604 9.15813 17.8915 9.02489C17.8226 8.89165 17.7277 8.77354 17.6125 8.67751C17.4973 8.58148 17.364 8.50946 17.2205 8.4657C17.077 8.42193 16.9262 8.40731 16.777 8.42268C16.6278 8.43806 16.4831 8.48311 16.3516 8.55521C16.22 8.62731 16.1042 8.72499 16.011 8.8425L11.1735 14.6464L8.67037 12.1421C8.4582 11.9372 8.17402 11.8238 7.87905 11.8264C7.58408 11.8289 7.30191 11.9472 7.09333 12.1558C6.88475 12.3644 6.76643 12.6466 6.76387 12.9415C6.7613 13.2365 6.8747 13.5207 7.07963 13.7329L10.4546 17.1079C10.5652 17.2183 10.6975 17.3046 10.8432 17.3611C10.9889 17.4176 11.1448 17.4431 11.3009 17.436C11.457 17.4289 11.61 17.3893 11.75 17.3199C11.89 17.2504 12.0139 17.1525 12.114 17.0325L17.739 10.2825Z" fill="#00AB9A" />
                                </svg>
                                : data?.status === "perbaiki" || data?.status === "pending" ?
                                    <svg width="20" height="20" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M25 12.5C25 15.8152 23.683 18.9946 21.3388 21.3388C18.9946 23.683 15.8152 25 12.5 25C9.18479 25 6.00537 23.683 3.66117 21.3388C1.31696 18.9946 0 15.8152 0 12.5C0 9.18479 1.31696 6.00537 3.66117 3.66117C6.00537 1.31696 9.18479 0 12.5 0C15.8152 0 18.9946 1.31696 21.3388 3.66117C23.683 6.00537 25 9.18479 25 12.5ZM12.5 5.46875C12.5 5.26155 12.4177 5.06284 12.2712 4.91632C12.1247 4.76981 11.926 4.6875 11.7188 4.6875C11.5115 4.6875 11.3128 4.76981 11.1663 4.91632C11.0198 5.06284 10.9375 5.26155 10.9375 5.46875V14.0625C10.9375 14.2002 10.974 14.3355 11.0431 14.4545C11.1123 14.5736 11.2117 14.6723 11.3313 14.7406L16.8 17.8656C16.9795 17.9626 17.1898 17.9856 17.3859 17.9296C17.5821 17.8736 17.7486 17.7431 17.8498 17.5659C17.951 17.3888 17.9789 17.1791 17.9275 16.9817C17.8762 16.7843 17.7497 16.6147 17.575 16.5094L12.5 13.6094V5.46875Z" fill="#D7B700" />
                                    </svg>
                                    : null
                            }
                        </div>
                        <a
                            href={imageHandle(signatureFile?.path)}
                            download={`'laporan-${signatureFile?.id}'`}
                            target="_blank"
                            className='px-[8px] py-[7px] border border-gray-400 rounded-lg text-blue-thick text-[14px]'>
                            <BiPrinter className='text-lg' />
                        </a>
                    </div>
                </div>
                <div className='space-y-[24px] bg-white px-[28px] py-[40px] rounded-[12px] border'>
                    <div className=''>
                        <div className='grid grid-cols-6'>
                            <p className='text-dark-3 text-sm font-semibold'>Nama Pegawai</p>
                            <p className='col-span-5 text-dark-3 text-sm font-semibold'>: {data?.fullname}</p>
                        </div>
                        <div className='grid grid-cols-6'>
                            <p className='text-dark-3 text-sm font-semibold'>Status</p>
                            <p className='col-span-5 text-dark-3 text-sm font-semibold'>: {data?.status != "approved" ? data?.status : "Disetujui"}</p>
                        </div>
                        <div className='grid grid-cols-6'>
                            <p className='text-dark-3 text-sm font-semibold'>Periode</p>
                            <p className='col-span-5 text-dark-3 text-sm font-semibold'>: {moment(data?.start_date).format("D MMMM YYYY")} - {moment(data?.laporanTahunan?.end_date).format("D MMMM YYYY")}</p>
                        </div>

                        {/* <button
                            onClick={() => setVerifikasi(true)}
                            className='px-[8px] py-[7px] w-fit border border-gray-400 rounded-lg text-blue-thick text-[14px]'>
                            <img src={IconLocFingerprint} className='h-10 w-auto' />
                        </button> */}
                    </div>
                    <div className='space-y-[24px]'>
                        <div className='border p-5 rounded-[12px]'>
                            <h1 className='text-dark-3 text-[20px] font-[600]'>Hasil Kerja</h1>
                            <div className='mt-5'>
                                <h1 className="text-zinc-800 text-sm font-medium mb-3">Tugas Utama</h1>
                                <div className='mt-5 mb-[44px] overflow-auto'>
                                    <table className='min-w-full'>
                                        <thead>
                                            <tr className='bg-[#EBF7FF] text-grey-thick text-xs font-medium whitespace-nowrap'>
                                                <th rowSpan={2} className='border py-3 px-5'>No.</th>
                                                <th rowSpan={2} className='border py-3 px-5'>Tugas Struktural Atasan yang Diintervensi</th>
                                                <th rowSpan={2} className='border py-3 px-5'>Jenis Tugas</th>
                                                <th rowSpan={2} className='border py-3 px-5'>Tugas Struktural Asisten	</th>
                                                <th rowSpan={2} className='border py-3 px-5'>Target</th>
                                                <th colSpan={9} className='border py-3 px-5'>Tugas Pokok</th>
                                            </tr>
                                            <tr className='bg-[#EBF7FF] text-grey-thick text-xs font-medium whitespace-nowrap'>
                                                <th className='border py-3 px-5 min-w-[200px]'>Nama Tugas Pokok</th>
                                                <th className='border py-3 px-5 min-w-[200px]'>Target Kualitas</th>
                                                <th className='border py-3 px-5 min-w-[200px]'>Target Kuantitas</th>
                                                <th className='border py-3 px-5 min-w-[200px]'>Target Waktu</th>
                                                <th className='border py-3 px-5 min-w-[200px]'>Target Biaya</th>
                                                <th className='border py-3 px-5 min-w-[200px]'>Bukti Dukung</th>
                                                <th className='border py-3 px-5 min-w-[200px]'>Realisasi Kuantitas</th>
                                                <th className='border py-3 px-5 min-w-[200px]'>Realisasi Biaya</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dataRHK.map((val, index) => (
                                                <tr key={index} className='border'>
                                                    <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>{index + 1}.</td>
                                                    <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>{val?.rhk_atasan ?? "-"}</td>
                                                    <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>{val?.type}</td>
                                                    <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>{val?.rhk}</td>
                                                    <td className='border py-3 px-5 text-grey-thick text-xs font-medium text-center'>{val?.target_rhk}</td>
                                                    <td className='border py-0 px-0' colSpan={9}>
                                                        <table className='min-w-full'>
                                                            <tbody>
                                                                {val?.rencana_aksi && val?.rencana_aksi.map((v, i) => (
                                                                    <tr key={i} className='border'>
                                                                        <td className='py-3 px-5 text-grey-thick text-xs font-medium min-w-[200px]'>{v?.name}</td>
                                                                        <td className='border-l py-3 px-5 text-center text-grey-thick text-xs font-medium min-w-[200px]'>{v?.target_kualitas}</td>
                                                                        <td className='border-l py-3 px-5 text-center text-grey-thick text-xs font-medium min-w-[200px]'>{v?.target_kuantitas}</td>
                                                                        <td className='border-l py-3 px-5 text-center text-grey-thick text-xs font-medium min-w-[200px]'>{v?.target_waktu}</td>
                                                                        <td className='border-l py-3 px-5 text-center text-grey-thick text-xs font-medium min-w-[200px]'>{v?.target_biaya}</td>
                                                                        <td className='border-l py-3 px-5 text-center text-grey-thick text-xs font-medium min-w-[200px]'>
                                                                            <div className='flex items-center justify-center gap-3'>
                                                                                <p>{v?.kinerja_bulanan?.length ? v?.kinerja_bulanan?.length : '0'} Dokumen</p>
                                                                                <button
                                                                                    onClick={() => handleDetailBuktiDukung(v?.kinerja_bulanan, v?.bukti_periode)}
                                                                                    className='w-[29px] h-[29px] bg-[#CEDFEA] rounded-[9px] flex items-center justify-center'>
                                                                                    <IoEyeOutline className='text-blue-thick' />
                                                                                </button>
                                                                            </div>
                                                                        </td>
                                                                        <td className='border-l py-3 px-5 text-center text-grey-thick text-xs font-medium min-w-[200px]'>{v?.realisasi_kuantitas}</td>
                                                                        <td className='border-l py-3 px-5 text-center text-grey-thick text-xs font-medium min-w-[200px]'>{v?.realisasi_biaya}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='mt-5'>
                                <h1 className="text-zinc-800 text-sm font-medium mb-3">Tugas Tambahan</h1>
                                <div className='mt-5 mb-[44px] overflow-auto'>
                                    <table className='min-w-full'>
                                        <thead>
                                            <tr className='bg-[#EBF7FF] text-grey-thick text-xs font-medium whitespace-nowrap'>
                                                <th className='border py-3 px-5'>No.</th>
                                                <th className='border py-3 px-5'>Tanggal</th>
                                                <th className='border py-3 px-5'>Uraian Kegiatan</th>
                                                <th className='border py-3 px-5'>Dasar Penugasan</th>
                                                <th className='border py-3 px-5'>Hasil</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dataTambahan && dataTambahan.map((val, index) => (
                                                <tr className='border'>
                                                    <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>{index + 1}.</td>
                                                    <td className='border py-3 px-5 text-grey-thick text-xs font-medium max-w-[200px]'>{moment(val?.tanggal).format("D MMMM YYYY")}</td>
                                                    <td className='border py-3 px-5 text-grey-thick text-xs font-medium max-w-[200px]'>{val.uraian_kegiatan}</td>
                                                    <td className='border py-3 px-5 text-grey-thick text-xs font-medium max-w-[200px]'>{val?.dasar_penugasan}</td>
                                                    <td className='border py-3 px-5 text-grey-thick text-xs font-medium max-w-[200px]'>{val?.hasil}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className='border p-5 rounded-[12px]'>
                            <h1 className='text-dark-3 text-[20px] font-[600]'>Perilaku Kerja</h1>
                            <div className='mt-5 mb-[44px] overflow-auto'>
                                {dataPerilakuKerja && dataPerilakuKerja.map((evaluation, evaluationIndex) => (
                                    <table key={evaluationIndex} className='min-w-full'>
                                        <thead>
                                            <tr className='bg-[#EBF7FF] text-grey-thick text-xs font-medium whitespace-nowrap'>
                                                <th className='border py-3 px-5'>No.</th>
                                                <th className='border py-3 px-5 min-w-[200px]'>Perilaku</th>
                                                <th className='border py-3 px-5 min-w-[200px]'>Umpan Balik</th>
                                                <th className='border py-3 px-5 min-w-[200px]'>Umpan Balik (Bulanan)</th>
                                                <th className='border py-3 px-5 min-w-[200px]'>Umpan Balik Rekan Kerja</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {evaluation && evaluation.settings.map((setting, settingIndex) => (
                                                <tr className='border'>
                                                    <td className='border py-3 px-5 text-grey-thick text-xs font-medium'>{settingIndex + 1}.</td>
                                                    <td className='border py-3 px-5 text-grey-thick text-xs font-medium min-w-[200px]'>
                                                        <p className='text-grey-thick text-xs font-medium'>{setting.setting_name}</p>
                                                        <ul className='text-grey-thick text-xs font-medium list-disc ml-4'>
                                                            {setting && setting.points.map((point, pointIndex) => (
                                                                <li key={pointIndex}>{point.point_name}</li>
                                                            ))}
                                                        </ul>
                                                    </td>
                                                    <td className='border py-3 px-5 text-grey-thick text-xs text-center font-medium min-w-[200px]'>
                                                        {setting.feedback ? setting.feedback : "-"}
                                                    </td>
                                                    <td className='border py-3 px-5 text-grey-thick text-xs font-medium min-w-[200px]'>
                                                        {Object.entries(setting.penilaian_atasan).map(([category, count]) => (
                                                            <h1 className='text-[10px] rounded-lg px-[8px] py-1 w-fit'>
                                                                <span>{category}</span> : <span>{count}</span>
                                                            </h1>
                                                        ))}
                                                    </td>
                                                    <td className='border py-3 px-5 text-grey-thick text-xs font-medium min-w-[200px]'>
                                                        {Object.entries(setting.penilaian_rekan_kerja).map(([category, count]) => (
                                                            <h1 className='text-[10px] rounded-lg px-[8px] py-1 w-fit'>
                                                                <span>{category}</span> : <span>{count}</span>
                                                            </h1>
                                                        ))}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ))}
                            </div>
                        </div>
                        <div className='my-5 space-y-2'>
                            <h1 className="text-zinc-800 text-base font-semibold">Pendidikan Terakhir</h1>
                            <div className='w-full grid grid-cols-2 gap-3'>
                                <div>
                                    <h1 className='text-neutral-500 text-[11px] font-medium mb-[5px]'>Tingkat Pendidikan Tertinggi</h1>
                                    <input value={dataPendidikan?.pendidikan_tertinggi} type='text' className='h-[35px] bg-[#DCDCDC] border rounded-[6px] text-dark-3 text-[13px] px-[8px] py-[10px] w-full appearance-none' placeholder='' readOnly />
                                </div>

                                <div>
                                    <h1 className='text-neutral-500 text-[11px] font-medium mb-[5px]'>Nama Program Studi</h1>
                                    <input value={dataPendidikan?.program_studi} type='text' className='h-[35px] bg-[#DCDCDC] border rounded-[6px] text-dark-3 text-[13px] px-[8px] py-[10px] w-full appearance-none' placeholder='' readOnly />
                                </div>
                            </div>
                        </div>
                        <div className='my-5 space-y-2'>
                            <h1 className="text-zinc-800 text-base font-semibold">SK Penetapan Inovasi</h1>
                            {dataFileSKInovasi?.length !== 0 || dataFileSKInovasiBulanan?.length !== 0 ?
                                <div className='space-y-2'>
                                    {dataFileSKInovasi && dataFileSKInovasi.map((file, idx) => (
                                        <div key={idx} className='flex items-center gap-10'>
                                            <div className='bg-[#CEDFEA] rounded-md flex gap-2 items-center p-2 text-xs'><HiPaperClip /> <span>{file?.originalname}</span></div>
                                            <a href={imageHandle(file?.file_path)} download={file?.file_name} target='_blank' rel='noreferrer' className='text-blue-primer font-semibold text-sm flex gap-2'>
                                                <BiDownload className='text-xl' />
                                                <p>Unduh Dokumen</p>
                                            </a>
                                        </div>
                                    ))}
                                    {dataFileSKInovasiBulanan && dataFileSKInovasiBulanan.map((file, idx) => (
                                        <div key={idx} className='flex items-center gap-10'>
                                            <div className='bg-[#CEDFEA] rounded-md flex gap-2 items-center p-2 text-xs'><HiPaperClip /> <span>{file?.originalname}</span></div>
                                            <a href={imageHandle(file?.file_path)} download={file?.file_name} target='_blank' rel='noreferrer' className='text-blue-primer font-semibold text-sm flex gap-2'>
                                                <BiDownload className='text-xl' />
                                                <p>Unduh Dokumen</p>
                                            </a>
                                        </div>
                                    ))}
                                </div>
                                :
                                <div className='flex items-center gap-10'>
                                    <div className='bg-[#CEDFEA] rounded-md flex gap-2 items-center p-2 text-xs'>Tidak Ada SK yg diunggah</div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default DetailLaporanSayaPeriode