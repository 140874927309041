import axios from 'axios';

const request = axios.create({
    timeout: 200000,
    headers: {
        Accept: "application/json",
    },
    withCredentials: true,
})

request.interceptors.response.use(
    response => response,
    error => {
        // Menangani kesalahan di sini
        if (error.response) {
            if (error.response.status === 419) {
                window.location.href = '/error/419';
                console.log(`Error ${error.response.status}: ${error.response.statusText}`);
            }else if (error.response.status === 403) {
                window.location.href = '/error/403';
                console.log(`Error ${error.response.status}: ${error.response.statusText}`);
            // } else if (error.response.status === 404) {
            //     window.location.href = '/error/404';
            //     console.log(`Error ${error.response.status}: ${error.response.statusText}`);
            // } else if (error.response && [500, 501, 502, 503, 504].includes(error.response.status)) {
            //     window.location.href = '/error/500';
            //     console.log(`Error ${error.response.status}: ${error.response.statusText}`);
            } else {
                console.error(`Error ${error.response.status}: ${error.response.statusText}`);
            }
        } else {
            console.error('Network error or no response');
            window.location.href = '/error/500';
        }
        return Promise.reject(error);
    }
);


export default request;